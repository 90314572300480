/**
 * In this file, we create a React component
 * which incorporates components provided by Material-UI.
 */
import React, { lazy, Suspense } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

const avalor_qa = lazy(() => import('../logins/avalor_qa'));
const avalor_prod = lazy(() => import('../logins/avalor_prod'));
const Claro = lazy(() => import('../logins/Claro'));
const Colsecor = lazy(() => import('../logins/Colsecor'));
const ColsecorPromo = lazy(() => import('../logins/ColsecorPromo'));
const Dhe = lazy(() => import('../logins/Dhe'));
const Dokifun = lazy(() => import('../logins/Dokifun'));
const DokiPrivacyInfo = lazy(() => import('../logins/Dokifun/PrivacyInfo'));
//const DokiSignIn = lazy((  => import(./logins/Dokifun/SignInForm'));
const EspnPromo = lazy(() => import('../logins/EspnPromo'));
const FoxAuth = lazy(() => import('../logins/FoxAuth'));
const FoxLoad = lazy(() => import('../logins/fox_load'));
const FoxSports = lazy(() => import('../logins/FoxSports'));
const NogginApp = lazy(() => import('../logins/NogginApp'));
const NogginCodes = lazy(() => import('../logins/NogginCodes'));
const HistoryCodes = lazy(() => import('../logins/HistoryCodes'));
const Nuplincine = lazy(() => import('../logins/Nuplincine'));
const FreeNuplincine = lazy(() => import('../logins/FreeNuplincine'));
const NuplincineRest = lazy(() => import('../logins/Nuplincine/Reset'));
const NotFound = lazy(() => import('../errors/404'));
const ParamountCodes = lazy(() => import('../logins/ParamountCodes'));
const ParamountGiftcard = lazy(() => import('../logins/ParamountGiftcard'));
const ParamountGiftcardBr = lazy(() => import('../logins/ParamountGiftcardBr'));
const PrensaToolbox = lazy(() => import('../logins/PrensaToolbox'));
const ToolboxIDP = lazy(() => import('../logins/ToolboxIDP'));
const PrensaTyc = lazy(() => import('../logins/PrensaTyc'));
const PrensaHotgo = lazy(() => import('../logins/PrensaHotgo'));
const PrensaTurner = lazy(() => import('../logins/PrensaTurner'));
const PrensaDiscovery = lazy(() => import('../logins/PrensaDiscovery'));
const prensatvquality = lazy(() => import('../logins/prensatvquality'));
const prensa_nbcu = lazy(() => import('../logins/Prensa_nbcu'));
const UniversalIDP = lazy(() => import('../logins/UniversalIDP'));
const HistoryIDP = lazy(() => import('../logins/HistoryIDP'));
const PrensaTelecine = lazy(() => import('../logins/PrensaTelecine'));
const PrensaSony = lazy(() => import('../logins/PrensaSony'));
const PrensaFox = lazy(() => import('../logins/PrensaFox'));
const RappiCodes = lazy(() => import('../logins/RappiCodes'));
const Telecine = lazy(() => import('../logins/Telecine'));
const TNTSports = lazy(() => import('../logins/TNTSports'));
const Viacom = lazy(() => import('../logins/Viacom'));
const HistoryCodesbr = lazy(() => import('../logins/HistoryCodesbr'));
const convidados_fox_sports = lazy(() => import('../logins/convidados_fox_sports'));
const invitados_fox_sports = lazy(() => import('../logins/invitados_fox_sports'));
const Tyc = lazy(() => import('../logins/Tyc'));
const CacheMe = lazy(() => import('../logins/CacheMe'));
const iplan = lazy(() => import('../logins/iplan'));
const CloudpayDemo = lazy(() => import('../logins/CloudpayDemo'));
const Directvgo = lazy(() => import('../logins/directvgo'));
const Dtvgo = lazy(() => import('../logins/Dtvgo'));
const ViacomTim = lazy(() => import('../logins/viacom_tim'));
const promocodes_nbcu = lazy(() => import('../logins/promocodes_nbcu'));
const toolbox_marketplace = lazy(() => import('../logins/ToolboxMarketplace'));
const nbcu_account = lazy(() => import('../logins/NbcuAccount'));
const nbcu_session = lazy(() => import('../logins/NbcuSession'));
const nbcu = lazy(() => import('../logins/Nbcu'));
// Noggin and Paramount
const ViacomGiftcards = lazy(() => import('../logins/ViacomGiftcards'));
const prensa_sony = lazy(() => import('../logins/prensa_sony'));
const Hispanomedios = lazy(() => import('../logins/Hispanomedios'));
const SportsHub = lazy(() => import('../logins/SportsHub'));
const TbxGo = lazy(() => import('../logins/TbxGo'));
const Winsports = lazy(() => import('../logins/Winsports'));
const Teatrix = lazy(() => import('../logins/Teatrix'));
const Fanatiz = lazy(() => import('../logins/Fanatiz'));
const PrensaTbxgo = lazy(() => import('../logins/Prensa_tbxgo'));
const MotorPlay = lazy(() => import('../logins/MotorPlay'));

import '../styles/main.scss';

class Main extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.validateParam('failureRedirect');
    this.validateParam('redirect_uri');
  }

  validateParam = (param) => {
    const params = new URLSearchParams(location.search);
    const queryParm = params.get(param);
    if (queryParm && !queryParm.match(/^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi)) {
      console.warn(`BLOCKED INSECURE QUERY PARAM - ${param}`);
      params.set(param, '#');
      history.replaceState(null, '', `?${params}${location.hash}`);
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Suspense fallback={<br/>}>
            <Route path="/avalor_qa" component={avalor_qa}/>
            <Route path="/convidados_fox_sports" component={convidados_fox_sports}/>
            <Route path="/invitados_fox_sports" component={invitados_fox_sports}/>
            <Route path="/avalor_prod" component={avalor_prod}/>
            <Route path="/claro" component={Claro}/>
            <Route path="/colsecor" component={Colsecor}/>
            <Route path="/colsecor-promo" component={ColsecorPromo}/>
            <Route path="/dhe" component={Dhe}/>
            <Route exact path="/doki-fun" component={Dokifun}/>
            <Route path="/doki-fun/privacy" component={DokiPrivacyInfo}/>
            <Route path="/espn-promo" component={EspnPromo}/>
            <Route path="/foxauth" component={FoxAuth}/>
            <Route path="/fox_load" component={FoxLoad}/>
            <Route path="/foxsports" component={FoxSports}/>
            <Route path="/nogginapp" component={NogginApp}/>
            <Route path="/noggincodes" component={NogginCodes}/>
            <Route path="/nuplincine/reset/:resetCode" component={NuplincineRest}/>
            <Route path="/nuplincine" component={Nuplincine}/>
            <Route path="/freenuplincine" component={FreeNuplincine}/>
            <Route path="/iplan" component={iplan}/>
            <Route path="/paramountcodes" component={ParamountCodes}/>
            <Route path="/paramountgiftcard" component={ParamountGiftcard}/>
            <Route path="/prensatoolbox" component={PrensaToolbox}/>
            <Route path="/toolboxidp" component={ToolboxIDP}/>
            <Route path="/prensatyc" component={PrensaTyc}/>
            <Route path="/hispanomedios" component={Hispanomedios}/>
            <Route path="/sportshub" component={SportsHub}/>
            <Route path="/tbxgo" component={TbxGo}/>
            <Route path="/prensahotgo" component={PrensaHotgo}/>
            <Route path="/prensaturner" component={PrensaTurner}/>
            <Route path="/prensadiscovery" component={PrensaDiscovery}/>
            <Route path="/prensatelecine" component={PrensaTelecine}/>
            <Route path="/prensasony" component={PrensaSony}/>
            <Route path="/prensafox" component={PrensaFox}/>
            <Route path="/rappicodes" component={RappiCodes}/>
            <Route path="/telecine" component={Telecine}/>
            <Route path="/tntsports" component={TNTSports}/>
            <Route path="/viacom" component={Viacom}/>
            <Route path="/historycodes" component={HistoryCodes}/>
            <Route path="/historycodesbr" component={HistoryCodesbr}/>
            <Route path="/tyc" component={Tyc}/>
            <Route path={['/noggingiftcards', '/paramountgiftcards']} component={ViacomGiftcards}/>
            <Route path="/prensatvquality" component={prensatvquality}/>
            <Route path="/prensa_sony" component={prensa_sony}/>
            <Route path="/prensa_nbcu" component={prensa_nbcu}/>
            <Route path="/cacheme" component={CacheMe}/>
            <Route path="/cloudpaydemo" component={CloudpayDemo}/>
            <Route path="/directvgo" component={Directvgo}/>
            <Route path="/viacom_tim" component={ViacomTim}/>
            <Route path="/paramountgiftcardBr" component={ParamountGiftcardBr}/>
            <Route path="/nbcuidp" component={UniversalIDP}/>
            <Route path="/dtvgo" component={Dtvgo}/>
            <Route path="/historyidp" component={HistoryIDP}/>
            <Route path="/promocodes_nbcu" component={promocodes_nbcu}/>
            <Route path="/toolbox_marketplace" component={toolbox_marketplace}/>
            <Route path="/nbcu_account" component={nbcu_account}/>
            <Route path="/nbcu_session" component={nbcu_session}/>
            <Route path="/nbcu" component={nbcu}/>
            <Route path="/winsports" component={Winsports}/>
            <Route path="/teatrix" component={Teatrix}/>
            <Route path="/fanatiz" component={Fanatiz}/>
            <Route path="/prensa_tbxgo" component={PrensaTbxgo}/>
            <Route path="/motorplay" component={MotorPlay}/>
          </Suspense>
          <Route path="*" component={NotFound}/>
        </Switch>

      </BrowserRouter>
    );
  }
}

export default Main;
