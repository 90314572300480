'use strict';

/* eslint-disable global-require */
if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

// Patch si el browser no soporta Fetch API:
if (!window.fetch) {
  // fetch() polyfill for making API calls.
  require('whatwg-fetch');
}

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

//require('raf').polyfill();

//Intl.DisplayNames polyfill
if (!Intl.DisplayNames) {
  require('@formatjs/intl-locale/polyfill');
  require('@formatjs/intl-displaynames/polyfill');
  require('@formatjs/intl-displaynames/locale-data/en');
  require('@formatjs/intl-displaynames/locale-data/es');
  require('@formatjs/intl-displaynames/locale-data/pt');
}